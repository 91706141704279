<template>
  <div class="team">
    <headbar :title="$t('我的社区')"></headbar>

    <div class="level-wrap">
      <div class="level-wrap-title">{{ $t('当前我的等级') }}</div>
      <div class="level-wrap-value" v-if="us">{{ us.xlevelName }}</div>
    </div>

    <div class="total">
      <div class="total-item">
        <img src="@/assets/img/user/t1.png" class="total-icon" />
        <div class="total-label">{{ $t('社区节点') }}</div>
        <div class="total-value">{{ $t('社区节点') }}：{{ data.teamcount }}</div>
        <div class="total-value">{{ $t('有效节点') }}：{{ data.youxiao }}</div>
      </div>
      <div class="total-item">
        <img src="@/assets/img/user/t2.png" class="total-icon" />
        <div class="total-label">{{ $t('社区业绩') }}</div>
        <div class="total-value">{{ $t('大区业绩') }}：{{ data.daqu }}</div>
        <div class="total-value">{{ $t('小区业绩') }}：{{ data.xiaoqu }}</div>
      </div>
      <div class="total-item" @click="$router.push('TouziRecord')">
        <img src="@/assets/img/user/t3.png" class="total-icon" />
        <div class="total-label" style="color: #e56efa;">{{ $t('我的套餐') }}</div>
        <div class="total-value">{{ $t('总认购') }}：{{ data.taocan }}</div>
      </div>
      <div class="total-item" @click="$router.push('Bonus')">
        <img src="@/assets/img/user/t4.png" class="total-icon" />
        <div class="total-label" style="color: #e56efa;">{{ $t('我的收益') }}</div>
        <div class="total-value">{{ $t('总收益') }}：{{ data.shouyi }}</div>
      </div>
    </div>

    <div style="text-align: center;color: #e56efa;font-weight: bold;font-size: 18px;margin-top: 20px;">{{ $t('我的直推') }}
    </div>
    <div class="list">
      <div class="list-label">
        <div class="row1">{{ $t('序号') }}</div>
        <div class="row2">{{ $t('地址') }}</div>
        <div class="row3">{{ $t('注册时间') }}</div>
        <div class="row1"></div>
      </div>
      <div v-for="(item, index) in data.list" :key="index">
        <div class="list-value">
          <div class="row1">{{ index + 1 }}</div>
          <div class="row2">{{ abbr(item.address, 4, 4) }}</div>
          <div class="row3">{{ item.rdt }}</div>
          <div class="row1" @click="onClick(item)">
            <van-icon v-if="item.more" name="arrow-down" />
            <van-icon v-else name="arrow-left" />
          </div>
        </div>
        <div class="list-more" v-show="item.more">
          <div class="list-more-value">
            <div>
              <span>{{ $t('等级') }}：</span>
              <span class="more-value">{{ item.ulevelName }}</span>
            </div>
            <div style="display: flex;">
              <div style="flex: 1;">
                <div>
                  <span>{{ $t('有效人数') }}：</span>
                  <span class="more-value">{{ item.youxiao }}</span>
                </div>
                <div>
                  <span>{{ $t('大区业绩') }}：</span>
                  <span class="more-value">{{ item.daqu }}</span>
                </div>
              </div>
              <div style="flex: 1;">
                <div>
                  <span>{{ $t('团队人数') }}：</span>
                  <span class="more-value">
                    {{ item.teamcount }}
                  </span>
                </div>
                <div>
                  <span>{{ $t('小区业绩') }}：</span>
                  <span class="more-value">
                    {{ item.xiaoqu }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <empty :text="$t('暂无记录')" v-if="data.list.length == 0" />
    </div>
  </div>
</template>

<script>
import CustomComponent from "@/components/CustomComponent";
import { Icon } from 'vant';
export default {
  name: '',
  components: {
    [Icon.name]: Icon,
  },
  data() {
    return {
      us: null,
      data: {
        list: []
      },
      list: [],
      teamyeji: 0,
      teamcount: 0
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      this.getTeamPageData()
    }
  },
  methods: {
    onClick(item) {
      item.more = item.more ? false : true
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    getTeamPageData() {
      let _this = this
      _this.$request.post(
        "api/Users/GetTeamPageData",
        {
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let data = res.data.data
          data.list.forEach(item => {
            item["more"] = false
          });
          _this.data = res.data.data
        }
      )
    },
    //   getList() {
    //     let _this = this
    //     _this.$request.post(
    //       "api/Users/GetUserListByReid",
    //       {
    //         
    //         userid: _this.us.userid,
    //         reid: _this.us.id
    //       },
    //       (res) => {
    //         if (res.data.code == 0) {
    //           _this.$q.dialog({
    //             component: CustomComponent,
    //             componentProps: {
    //               messages: [res.data.msg],
    //               persistent: true,
    //             }
    //           });
    //           return
    //         }
    //         let data = res.data.data.list
    //         _this.list = data
    //       }
    //     )
    //   }
  }
}
</script>

<style scoped>
.team {
  min-height: 100vh;
  padding: 10px;
  background-color: #080535;
}

.total {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.total-item {
  width: 48%;
  margin-bottom: 10px;
  padding: 20px 15px;
  border-radius: 10px;
  background-color: rgb(45, 38, 56);
}

.total-icon {
  margin-right: 10px;
  width: 40px;
  height: 40px;
}

.total-label {
  font-size: 16px;
  color: #fff;
}

.total-value {
  color: #aaaaaa;
  padding-top: 10px;
  font-size: 12px;
}

.list {
  margin-top: 15px;
  padding: 10px;
  border-radius: 10px;
  border: 0.5px solid rgb(82, 74, 101);
}

.list-label {
  display: flex;
  font-size: 12px;
  color: #999999;
}

.list-value {
  padding: 6px 0;
  display: flex;
  color: #fff;
}


.level-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 20px 15px;
  color: #fff;
  background: rgb(45, 38, 56);
  border-radius: 10px;
  font-size: 15px;
}

.row1,
.row2,
.row3 {
  text-align: center;
}

.row1 {
  flex: 1;
}

.row2 {
  flex: 3;
}

.row3 {
  flex: 4;
}

.list-more {
  color: #fff;
  padding: 0 0 20px 10px;
}

.list-more-value {
  text-align: left;
}

.more-value {
  color: #e56efa;
  overflow-wrap: break-word;
}
</style>